import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards"

const Contract = () => (
  <Layout>
    <SEO title="Πακέτα υποστήριξης" />
    <h1 className="mt-5 text-center">Ο κατάλογός μας</h1>
    <div className="text-center card-body" id="subHeading">Οι ανάγκες σας είναι σχεδόν πάντα διαφορετικές. Δείτε τα παρακάτω προγράμματα, και πείτε μας ποιο σας ενδιαφέρει για να βρούμε μαζί την χρυσή τομή για εσάς και την επιχείρησή σας.</div>
    <Cards></Cards>
    <span className="clear"></span>
  </Layout>
)

export default Contract
